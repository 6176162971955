import { React, useEffect, useState } from 'react';
import DefaultLayout from "layouts/DefaultLayout";
import ExportSpecificList from 'shared/views/export/SpecificList';
import ExportApi from 'shared/api/Export';

export default function ExportList(props) {
    const [totalExports, setTotalExports] = useState(0);

    useEffect(() => {
        ExportApi.getTotalAmount().then(data => {setTotalExports(data.amount)});
        // eslint-disable-next-line
    } , []);

    return (
        <DefaultLayout title="Mes exports">
            <h1>Mes exports</h1>
            {totalExports > 0 && <>
                {process.env.REACT_APP_CONTEXT === 'frontoffice' && <>
                    <ExportSpecificList type="sheetaffected" title="Export de fiches affectées" />
                    <ExportSpecificList type="requirementaffected" title="Export des exigences affectées" />
                </>}
                <ExportSpecificList type="hotline" title="Export hotline" />
                {process.env.REACT_APP_CONTEXT === 'backoffice' && <>
                    <ExportSpecificList type="sheet" title="Export recherche avancée" />
                    <ExportSpecificList type="dashboard" title="Export tableau de bord" />
                    <ExportSpecificList type="watchsite" title="Export points de veille" />
                    <ExportSpecificList type="connectionlogs" title="Exports d'historique de connexions" />
                    <ExportSpecificList type="sheetaffected" title="Fond documentaire" />
                </>}
                <ExportSpecificList type="obligationgenerator" title="Export obligations réglementaires" />
            </>}
            {totalExports === 0 &&
                <span>Aucun export disponible</span>
            }
        </DefaultLayout>
    );
}