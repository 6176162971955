import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';
import FileApi from 'shared/api/File';

const PREFIX = "watchsites";

const WatchsiteApi = {

    save: function(data) {
        return AbstractApi.save(PREFIX, data);
    },

    get: function(id) {
        return AbstractApi.get(PREFIX, id);
    },

    list: function(account = null, limit = 200) {
        let params = {};

        if (account) {
            params.account = account;
        }
        if (limit) {
            params.limit = limit;
        }
        return AbstractApi.list(PREFIX, params);
    },

    export: function(query, fileName) {
        return AbstractApi.export(PREFIX, query, "", fileName);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            PREFIX + "/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    uploadLogo: function(files) {
        return FileApi.upload(PREFIX + "/upload/logo", files);
    },

    uploadAttachments: function(files) {
        return FileApi.upload(PREFIX + "/upload/attachments", files);
    },
    
    transfer: function(watchsite, account) {
        return ApiRequest.post(PREFIX + "/transfer", {"watchsite": watchsite, "targetaccount": account});
    }
}
export default WatchsiteApi;
